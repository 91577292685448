import React from "react";

import { Field } from "formik";
// import Upload from '../controls/Upload';
import Upload from "../controls/Upload";

const FormUpload = ({ title }) => {
  return (
    <div>
      <div className="title text-center">
        <h3>{title}</h3>
      </div>
      <div className="form-customer">
        <div className="form-group text-center">
          <Field type="file" name="upload.file" className="btn-start upload" component={Upload} isInvalid={true}/>
        </div>
      </div>
    </div>
  );
};

export default FormUpload;
