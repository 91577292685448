import React from "react";
import "../../Assets/Styles/CustomerForm.css";
import { Field } from "formik";
import TextBox from "../controls/TextBox";
import ChecktBox from "../controls/ChecktBox";

const FormCustomer = ({ title }) => {
  return (
    <div>
      <div className="title text-center">
        <h3>{title}</h3>
      </div>
      <Field type="text" name="name" placeholder="ชื่อ" component={TextBox} />
      <Field
        type="Email"
        name="email"
        label="อีเมลที่ต้องการให้ส่งผลการประเมิน"
        placeholder="อีเมล์"
        component={TextBox}
      />
      <Field
        type="text"
        name="tel"
        label="เบอร์โทรที่ต้องการให้ทีมผู้เชี่ยวชาญให้คำปรึกษา"
        placeholder="เบอร์โทรศัพท์"
        component={TextBox}
      />
      <Field
        type="checkbox"
        name="policy"
        htmlFor="exampleCheck1"
        id="exampleCheck1"
        label={`ข้าพเจ้ายินยอมให้ บริษัท เดอร์มาลิงค์ จำกัด ใช้และเก็บรวบรวมข้อมูลที่ข้าพเจ้าให้ไว้ใน เว็บไซต์ของบริษัทฯ เพื่อการติดต่อและดำเนินการใดๆ เกี่ยวกับการสมัครใช้ผลิตภัณฑ์และ/ หรือรับบริการของบริษัทฯ ข้าพเจ้ายืนยันว่าข้อมูลที่ข้าพเจ้าให้ไว้ในเว็บไซต์นี้ถูกต้องตรงกับความจริงทุกประการ`}
        component={ChecktBox}
      />
    </div>
  );
};

export default FormCustomer;
