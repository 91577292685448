import PropTypes from 'prop-types';

const Item =(props)=>{
    const {image,title,detail} = props
    return (
        <div className='bg-container'>
            <div className='bg-tr'>
              <div className='bg-colum-img'><img className="icon-gine" src={image} alt="" /></div>
              <div className='bg-colum-txt'><p><span><b>{title}</b><br/>{detail}</span></p></div>
            </div>
          </div>
    );
}

Item.propTypes={
    title:PropTypes.string.isRequired,
    detail:PropTypes.string.isRequired
}
export default Item