import React from "react";
import { FieldArray, Field } from "formik";
import "../../Assets/Styles/CustomerForm.css";
import "../../Assets/Styles/QuestionForm.css";
import FormCheckCard from "../controls/FormCheckCard";

const FormQuestion = ({ name, title }) => {
  const displayField = (item, index, isError) => {
    switch (item.controlType) {
      case "radio":
        return (
          <Field
            id={item.id}
            controlType="radio"
            name={`${name}[${index}].answer`}
            options={item.options}
            component={FormCheckCard}
            questionId={item.id}
            useoThorText={item.useoThorText}
          />
        );
      case "checkbox":
        return (
          <Field
            id={item.id}
            controlType="checkbox"
            name={`${name}[${index}].answer`}
            options={item.options}
            component={FormCheckCard}
            questionId={item.id}
            useoThorText={item.useoThorText}
          />
        );
      default:
        break;
    }
  };

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => {
        const values = arrayHelpers.form.values;
        const errors = arrayHelpers.form.errors;
        // const isError = errors[name] ?? [];
        return (
          <div className="form-question">
            <div className="title text-center">
              <h3>{title}</h3>
            </div>
            {/* {isError && <div>กรุณาทำให้ครบ</div>} */}
            {values[name] && values[name].length > 0 ? (
              values[name].map((item, index) => {
                const isError = errors[`${name}[${index}].answer`];
                return (
                  <div key={index}>
                    <label> {item.title}</label>
                    {displayField(item, index, isError)}
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        );
      }}
    ></FieldArray>
  );
};

export default FormQuestion;
