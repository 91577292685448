import StartPage from './Pages/StartPage';
import FormRegister from './Pages/Form';
// import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import SuccessPage from './Pages/SuccessPage';
import TestPage from './Pages/TestPage';
import Profiles from './Pages/Profiles';

// เพิ้ม libery Axiox ทำ database 
// import Axios from 'axios';


import './App.css';

function App() {

  // const [questionList,setQuestion] = useState([]);
  // const getQuestion = () => {
  //   Axios.get('http://localhost:3001/question').then((response) => {
  //     console.log("getQuestion");
  //     setQuestion(response.data);
  //   })
  // }
  
  return (
    <div className="App">
      <BrowserRouter basename='/meso-questions'>
          <Routes>
            <Route path="/" element={<StartPage />} exact/>
            <Route path="/register" element={<FormRegister />}/>
            <Route path="/successed" element={<SuccessPage />}/>
            <Route path="/testpage" element={<TestPage />}/>
            <Route path="/profiles/:id" element={<Profiles />}/>
            <Route path="/profiles/" element={<Profiles />}/>
          </Routes>
      </BrowserRouter>
      {/* <div>
        <button type='button' onClick={getQuestion}>
          Shop Question 
        </button>
        {questionList.map((val, key) =>{
          return (
            <div key={key}>
              <p>ชื่อ : {val.name}</p>
            </div>
          )
        })}
      </div>*/}
    </div> 
  );
}

export default App;
