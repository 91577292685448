import React from "react";
import { Form } from "react-bootstrap";

const TextBox = ({ field, form: { touched, errors }, ...props }) => {
  const isError = errors[field.name] && touched[field.name];
  return (
    <Form.Group className="form-group">
      <Form.Label>{props?.label}</Form.Label>
      <Form.Control {...field} {...props}  isInvalid={isError} feedback={isError}/>
      {isError && <div className="error">{errors[field.name]}</div>}
    </Form.Group>
  );
};

export default TextBox;
