import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useFormikContext, Field } from "formik";
import TextBox from "../controls/TextBox";

const getDescendantProp = (obj, path) => {
  const arr = path.split(/[.[]['"]?/);
  let o = obj;
  while (arr.length && o) {
    o = o[arr.shift().replace(/['"]?]$/, "")];
  }
  return o;
};

const FormCheckCard = ({
  field,
  form: { touched, errors },
  options,
  controlType,
  questionIndex,
  questionId,
  useoThorText,
  ...props
}) => {
  const isError = errors[field.name] !== undefined
  const formikContext = useFormikContext();
  // const isError =
  //   getDescendantProp(errors, field.name) !== undefined &&
  //   getDescendantProp(touched, field.name) !== undefined;

  const [displayFormOthor, setDisplayFormOthor] = React.useState({
    optionIndex: 0,
    value: false,
  });
  const [message, setMessage] = React.useState(
    formikContext.values.others?.[questionId]?.value ?? ""
  );

  const hadleClickCheckbox = (e, item, index) => {
    const targetChecked = e.target.checked;

    // console.log("targetChecked :", targetChecked);
    // console.log("useoThorText :", useoThorText);
    // console.log("item.id :", item.id);
    if (item.id === 6 && useoThorText) {
      setDisplayFormOthor({ optionIndex: index, value: targetChecked });
      const dataList = formikContext.values.others;

      // console.log("dataList :", dataList);
      // console.log("questionId :", questionId);
      dataList.push({ id: questionId, value: "" });
      formikContext.setFieldValue("others", dataList);
    }
    if (!targetChecked) {
      const dataList = formikContext.values.others;
      setMessage("");
      formikContext.setFieldValue(
        "others",
        dataList.filter((item) => item.id !== questionId)
      );
    }
  };

  const handleBlurInputOther = (e) => {
    const value = e.target.value;
    const dataList = formikContext.values.others;
    const dataFind = dataList.find((item) => item.id === questionId);

    // console.log("value :", value);
    // console.log("dataList :", dataList);
    // console.log("dataFind :", dataFind);
    if (dataFind) {
      const lists = dataList.map((item) => {
        if (item.id === questionId) {
          item.value = value;
        }
        return item;
      });
      formikContext.setFieldValue("others", lists);
    } else {
      dataList.push({ id: questionId, value: value });
      formikContext.setFieldValue("others", dataList);
    }
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  const displayListOption = () => {
    switch (options.length) {
      case 5:
        return (
          <Row>
            {options.map((item, index) => {
              const length = index + 1;
              let match = undefined;
              if (Array.isArray(field.value)) {
                match = field.value.find((i) => i === item.label);
              }
              if (controlType === "radio") {
                return (
                  <Col xs={length === 5 ? 12 : 6} key={index}>
                    <Form.Check
                      key={item.id}
                      type={controlType}
                      id={`check-api-radio-${props.id}-${item.id}`}
                    >
                      <Form.Check.Input
                        type={controlType}
                        {...field}
                        value={item.label}
                        isInvalid={isError}
                        checked={item.label === field.value}
                      />
                      <Form.Check.Label
                        htmlFor={`check-api-radio-${props.id}-${item.id}`}
                      >
                        {item.label}
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                );
              } else {
                return (
                  <Col xs={length === 5 ? 12 : 6} key={index}>
                    <Form.Check
                      key={item.id}
                      type={controlType}
                      id={`check-api-checkbox-${props.id}-${item.id}`}
                    >
                      <Form.Check.Input
                        type={controlType}
                        {...field}
                        value={item.label}
                        isInvalid={isError}
                        defaultChecked={match ? true : false}
                      />
                      <Form.Check.Label
                        htmlFor={`check-api-checkbox-${props.id}-${item.id}`}
                      >
                        {item.label}
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                );
              }
            })}
          </Row>
        );
      case 6:
        return (
          <Row>
            {options.map((item, index) => {
              let match = undefined;
              if (Array.isArray(field.value)) {
                match = field.value.find((i) => i === item.label);
              }
              if (controlType === "radio") {
                return (
                  <Col xs={6} key={index}>
                    <Form.Check
                      key={item.id}
                      type={controlType}
                      id={`check-api-radio-${props.id}-${item.id}`}
                    >
                      <Form.Check.Input
                        type={controlType}
                        {...field}
                        value={item.label}
                        isInvalid={isError}
                        defaultChecked={match ? true : false}
                        checked={item.label === field.value}
                      />
                      <Form.Check.Label
                        htmlFor={`check-api-radio-${props.id}-${item.id}`}
                      >
                        {item.label}
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                );
              } else {
                return (
                  <Col xs={6} key={index}>
                    <Form.Check
                      key={item.id}
                      type={controlType}
                      id={`check-api-checkbox-${props.id}-${item.id}`}
                    >
                      <Form.Check.Input
                        type={controlType}
                        {...field}
                        value={item.label}
                        isInvalid={isError}
                        defaultChecked={match ? true : false}
                        onClick={(event) =>
                          hadleClickCheckbox(event, item, index)
                        }
                      />
                      <Form.Check.Label
                        htmlFor={`check-api-checkbox-${props.id}-${item.id}`}
                      >
                        {item.label}
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                );
              }
            })}
          </Row>
        );
      case 4:
        return (
          <Row>
            {options.map((item, index) => {
              let match = undefined;
              if (Array.isArray(field.value)) {
                match = field.value.find((i) => i === item.label);
              }
              if (controlType === "radio") {
                return (
                  <Col xs={12} key={index}>
                    <Form.Check
                      key={item.id}
                      type={controlType}
                      id={`check-api-radio-${props.id}-${item.id}`}
                    >
                      <Form.Check.Input
                        type={controlType}
                        {...field}
                        value={item.label}
                        isInvalid={isError}
                        checked={item.label === field.value}
                      />
                      <Form.Check.Label
                        htmlFor={`check-api-radio-${props.id}-${item.id}`}
                      >
                        {item.label}
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                );
              } else {
                return (
                  <Col xs={12} key={index}>
                    <Form.Check
                      key={item.id}
                      type={controlType}
                      id={`check-api-checkbox-${props.id}-${item.id}`}
                    >
                      <Form.Check.Input
                        type={controlType}
                        {...field}
                        value={item.label}
                        isInvalid={isError}
                        defaultChecked={match ? true : false}
                      />
                      <Form.Check.Label
                        htmlFor={`check-api-checkbox-${props.id}-${item.id}`}
                      >
                        {item.label}
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                );
              }
            })}
          </Row>
        );
      default:
        break;
    }
  };
  return (
    <div>
      <Form.Group className="form-group" controlId="formRadio">
        {displayListOption()}
        <div style={{ display: displayFormOthor.value ? "block" : "none" }}>
          <Field
            type="text"
            name={`textOther-${questionIndex}`}
            placeholder="กรุณากรอกข้อมูลเพิ่มเติม"
            value={message}
            onBlur={handleBlurInputOther}
            onChange={handleChange}
            component={TextBox}
          />
        </div>
      </Form.Group>
    </div>
  );
};

export default FormCheckCard;
