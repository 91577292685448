import '../Assets/Styles/startPage.css';
import Iclock from '../Assets/icons/icon-clock.svg';
import logo from '../Assets/Images/meso-logo.png';
import { Link } from 'react-router-dom';
import ListTable from '../Components/tableStart';

function StartPage() {
    return (
        <div className='bg-question '>
            <div className="py-5 text-center">
              <img className="logo d-block mx-auto mb-4" src={logo} alt="" />
                <h2>ประเมินสภาพฝ้าฟรี</h2>
                <div className="bg-icon-clock">
                  <img className="icon-clock" src={Iclock} alt="" />
                  <span>ใช้เวลาเพียง 5 นาที เท่านั้น</span>
                </div>
                <div className='bg-table'>
                    <ListTable />
                </div>
                <div>
                  <Link to="/register" className='btn-start btn'>
                    เริ่มการประเมิน
                  </Link>
                </div>
            </div>
        </div>
      );
}

export default StartPage