import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import logo from "../Assets/Images/meso-logo.png";
import bgUpload from "../Assets/Images/bg-upload.png";

const Profiles = () => {
  const { id } = useParams();
  const [fetchedData, setFetchedData] = useState([]);

  console.log( "id" ,id)
  if(id  === undefined){
    window.location.href = "/";
    console.log( "id is null" ,id)
  }

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await axios.get(`https://mesoestetic-dev.com/profiles/${id}`);
    if (result && result.data.length > 0) {
      const data = result.data.map((item) => {
        item.question = JSON.parse(item.question);
        return item;
      });

      setFetchedData(data);
    }
  };

  const questionTitle = [
    { id: 1, title: "1. คุณอยู่ในช่วงอายุเท่าไร?" },
    { id: 2, title: "2. งบประมาณในการรักษาฝ้าให้หาย?" },
    { id: 3, title: "3. ปัญหาผิวอื่นๆ ที่ท่านมี?" },
    { id: 4, title: "4. สิ่งที่ท่านกังวลในการรักษาฝ้า " },
    { id: 5, title: "5. เวลาที่สะดวกในการโทรติดต่อ" },
  ];
  console.log("fetchedData: ", fetchedData);
  return (
    <div className="bg-question profiles">
      <div className="py-2 text-center">
        <img className="logo d-block mx-auto mb-4" src={logo} alt="" />
      </div>
      {fetchedData.map((item, index) => (
        <div key={index}>
          
      <div className="form-group text-center">
        <div className="img-upload">
          <img src={item.upload} />
          {/* <img src={bgUpload} /> */}
        </div>
      </div>
          <div className="form-group">
            <label htmlFor="">
              <strong>ชื่อ-นามสกุล : </strong> {item.name}
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="">
              <strong>อีเมล์ : </strong>{" "}
              <a href={"mailto:" + item.email}>{item.email}</a>
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="">
              <strong>เบอร์โทรศัพท์ : </strong>{" "}
              <a href={"tel:" + item.tel}>{item.tel}</a>
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="">
              <strong>แบบสอบถาม : </strong>
            </label>
            {item.question.map((answer, answerIndex) => (
              <div key={answerIndex}>
                <label htmlFor="" className="ques-title">
                  <strong>{questionTitle[answerIndex].title} : </strong>
                  {answer.answer}
                </label>
              </div>
            ))}
          </div>
          <div className="form-group">
            <label htmlFor="">
              <strong>คำถามเพิ่มเติม : </strong>{" "}
              {item.others}
            </label>
          </div>
        </div>
      ))}
    </div>
  );
  // return (
  //   <div>
  //     <div className="bg-question ">
  //       <div className="py-5 text-center">
  //         <img className="logo d-block mx-auto mb-4" src={logo} alt="" />
  //       </div>
  //       <div className="form-group text-center">
  //         <div className="img-upload">
  //           <img src="http://localhost:3001/uploads/241471602_360874742351568_2763173771394637531_n.webp" />
  //         </div>
  //       </div>
  //       <div className="form-group">
  //         <label htmlFor="">ชื่อ-นามสกุล : </label>
  //       </div>
  //       <div className="form-group">
  //         <label htmlFor="">เบอร์โทรศัพท์ : </label>
  //       </div>
  //       {/* <div className="form-group">
  //         {questionTitle.length > 0 ? (
  //           questionTitle.map((item) => {
  //             // if (item.id === iquestion.id) {txtquestion.map((iquestion) => { })
  //             return (
  //               <>
  //                 {questionTitle.map((iquestion, index) => {
  //                   // console.log("txtquestion 1 :", txtquestion);
  //                   if (item.id === iquestion.id) {
  //                     // console.log("txtquestion 2 :", txtquestion);
  //                     return (
  //                       <div key={index}>
  //                         <h4> {iquestion.title}</h4>
  //                       </div>
  //                     );
  //                   }
  //                 })}
  //               </>
  //             );
  //             //   }
  //           })
  //         ) : (
  //           <></>
  //         )}
  //       </div> */}
  //     </div>
  //   </div>
  // );
};

export default Profiles;
