import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useFormikContext } from "formik";
import bgUpload from "../../Assets/Images/bg-upload.png";

const getDescendantProp = (obj, path) => {
  const arr = path.split(/[.[]['"]?/);
  let o = obj;
  while (arr.length && o) {
    o = o[arr.shift().replace(/['"]?]$/, "")];
  }
  return o;
};

const TestUpload = ({ field, form: { touched, errors }, ...props }) => {
  const isError =
    getDescendantProp(errors, field.name) !== undefined &&
    getDescendantProp(touched, field.name) !== undefined;
  const formikProps = useFormikContext();

  const [image, setImage] = useState("");

  const handleChange = (e) => {
    // console.log("e.target.file", e.target.files);
    const targetFile = e.currentTarget.files[0];
    field.onChange(e);
    formikProps.setFieldValue("upload.targetFile", targetFile);
    setImage(e.target.files[0]);
    const formData = new FormData();

    formData.append("image", image);

    // console.log("formData*******", image);
  };

  //   const handleApi =() => {
  //     const url = 'http://localhost:3001/api/image'

  //     console.log("formData*******", formData)
  //     console.log("formData*******", image)
  //     // Axios.post(url, formData).then((res) =>{
  //     //   console.log(res.data)
  //     // }).catch((err) => {
  //     //   console.log(err)
  //     // })
  //   }

  return (
    <Form.Group className="mb-3">
      <div className="form-group text-center">
        <div className="img-upload">
          <img src={image === "" ? bgUpload : URL.createObjectURL(image)} />
        </div>
      </div>

      <Form.Control
        {...props}
        name={field.name}
        multiple
        accept="image/*"
        onChange={handleChange}
        isInvalid={isError}
      />
    </Form.Group>
  );
};

export default TestUpload;
