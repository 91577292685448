import React from "react";
import logo from "../Assets/Images/meso-logo.png";
import ButtonLine from "../Assets/Images/btn-line.png";
import "../Assets/Styles/startPage.css";

const SuccessPage = () => {
  return (
    <div className="bg-question bg-form-success">
      <div className="py-5 text-center">
        <img className="logo d-block mx-auto mb-4" src={logo} alt="" />
        <h1>Thank You</h1>
        <div>
          <span >
            ขอบคุณที่ประเมิน <br />
            ทางเราจะแจ้งผลการประเมิน<br />
            ภายใน 1-3 วันทำการ
          </span>
        </div>
        <div>
          <a href="https://page.line.me/mesoestetic?openQrModal=true" className="btn">
            <img
              className="d-block mx-auto mb-3 btn-line"
              src={ButtonLine}
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
