import React from "react";
import { Form } from "react-bootstrap";

const ChecktBox = ({ field, form: { touched, errors }, ...props }) => {
  const isError = errors[field.name] && touched[field.name];
  return (
    <Form.Group className="form-group checkBox">
      <Form.Check {...field} {...props} isInvalid={isError} feedback={isError} feedbackType="invalid" defaultChecked={field.value}/>
    </Form.Group>
  );
};

export default ChecktBox;
