import Item from './Item';
import Igine from '../Assets/icons/icon-gine.svg';
import Idoc from '../Assets/icons/icon-doc.svg';
import Isocial from '../Assets/icons/icon-social.svg';
//import { v4 as uuidv4 } from 'uuid';

const ListTable = ()=>{
    const listcontent = [
      {image:Igine,title:"เพื่อการรักษาที่มีประสิทธิ์ภาพ",detail:"รู้ระดับของฝ้าเบื้องต้นเพื่อที่จะเลือกวิธีการรักษาที่ถูกต้องและตรงจุด"},
      {image:Idoc,title:"ประเมินโดยทีมผู้เชี่ยวชาญ",detail:"ประเมินอย่างแม่นยำโดยผู้เชี่ยวชาญผ่านทางช่องทางออนไลน์ รับผลภายใน 1-3 วันทำการ"},
      {image: Isocial,title:"ปรึกษาผู้เชี่ยวชาญฟรี",detail:"ภายหลังจากการประเมินแล้ว สามารถที่จะปรึกษาทางผู้เชี่ยวชาญได้ฟรี เกี่ยวกับวิธีการในการดูแล และการรักษา"}
    ]
    return (
      listcontent.map((e,i)=>{
        return (
          //<Item image={e.image} title={e.title} detail={e.detail}  /> อันเดิม แบบยาวๆ
          <Item {...e} key={i}/>
        );
      })
    );

}
export default ListTable;