import logo from "../Assets/Images/meso-logo.png";
import { Form, Button, Nav } from "react-bootstrap";

import FormCustomer from "../Components/forms/FormCustomer";
import FormQuestion from "../Components/forms/FormQuestion";
import FormUpload from "../Components/forms/FormUpload";
import { Formik } from "formik";
import Moment from "moment";

import "../Assets/Styles/CustomerForm.css";
import React, { useState, useEffect } from "react";

// เพิ้ม libery Axiox ทำ database
import Axios from "axios";

function FormRegister() {
  const [activeTabKey, setActiveTabKey] = React.useState(1);
  const [tabs] = useState([
    {
      id: 1,
      name: "สวัสดีค่ะ",
      title: "ต้องการให้เราส่งผลการประเมินไปที่ไหน?",
    },
    {
      id: 2,
      name: "ตอบคำถามกันสักนิด",
      title: "ข้อมูลเพื่อใช้ในการประเมินฝ้า?",
    },
    {
      id: 3,
      name: "แบบประเมิน",
      title: "ถ่ายรูปเพื่อตรวจปัญหาฝ้า กระ จุดด่างดำ?",
    },
  ]);
  let formatDate = Moment().format("YYYY-MM-DD HH:mm:ss");

  // const checkValidate =(formikContext)=>{
  //   let errors = validateFormStep1(formikContext.values);
  //   return Object.keys(errors);
  // }

  // Get User ID
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch("https://mesoestetic-dev.com/userid")
      .then((response) => response.json())
      .then((data) => {
        // setPosts((posts) => data);
        setPosts(data[0].id);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);


  // const getuserID = () => {
  //   Axios.get('http://localhost:3001/userid').then((response) => {
  //     setuserID(response.data);
  //     console.log(setuserID)
  //   })
  // }
  // const uid = userID.map((val) =>{
  //   return(
  //     uid = val.id
  //   )
  // })
  // console.log(" val ", userID);

  const handleClickNext = async (formikContext) => {
    let errors = {};
    let listError = [];

    if (activeTabKey === 1) {
      errors = validateFormStep1(formikContext.values);
      listError = Object.keys(errors);
      listError.forEach((name) => {
        formikContext.setFieldTouched(name, true);
      });
      if (listError.length === 0) {
        setActiveTabKey((prev) => prev + 1);
      }
    }

    if (activeTabKey === 2) {
      const question = formikContext.values.question;
      question.forEach((item, index) => {
        if (item.id !== 5) {
          const dataType = typeof item.answer;
          if (dataType === "string" && item.answer === "") {
            errors[`question[${index}].answer`] = "Required";
          }

          if (Array.isArray(item.answer) && item.answer.length === 0) {
            errors[`question[${index}].answer`] = "Required";
          }
          formikContext.setErrors(errors);
        }
      });

      listError = Object.keys(errors);
      if (listError.length === 0) {
        setActiveTabKey((prev) => prev + 1);
      }

      // if (formikContext.values.name === "") {
      //   formikContext.setFieldError('name', 'foooooooooooooooooooooooooo')
      //   formikContext.setFieldTouched('name', true);
      // } else {
      //   setActiveTabKey((prev) => prev + 1);
      // }
    }

    if (activeTabKey === 3) {
      errors = validateFormStep3(formikContext.values);
      listError = Object.keys(errors);
      listError.forEach((name) => {
        formikContext.setFieldTouched("upload.file", true);
      });

      if (listError.length === 0) {
        formikContext.submitForm();
      }
    }
  };

  const handleClickBack = () => {
    if (activeTabKey > 1) {
      setActiveTabKey((prev) => prev - 1);
    }
  };

  const displayContent = (activeKey) => {
    const title = tabs.find((item) => item.id === activeTabKey)?.title ?? "";
    switch (activeKey) {
      case 1:
        return <FormCustomer title={title} />;
      case 2:
        return <FormQuestion name="question" title={title} />;
      case 3:
        return <FormUpload title={title} />;
      default:
        return <div></div>;
    }
  };

  const initialValues = {
    userid: posts,
    name: "",
    email: "",
    tel: "",
    policy: false,
    question: [
      {
        id: 1,
        answer: "",
        controlType: "radio",
        title: "1. คุณอยู่ในช่วงอายุเท่าไร?",
        useoThorText: false,
        options: [
          {
            id: 1,
            label: "อายุต่ำกว่า 18 ปี",
          },
          {
            id: 2,
            label: "18-25",
          },
          {
            id: 3,
            label: "26-35",
          },
          {
            id: 4,
            label: "36-45",
          },
          {
            id: 5,
            label: "46-55",
          },
          {
            id: 6,
            label: "อายุมากกว่า 55 ปี",
          },
        ],
      },
      {
        id: 2,
        answer: "",
        controlType: "radio",
        title: "2. งบประมาณในการรักษาฝ้าให้หาย?",
        useoThorText: false,
        options: [
          {
            id: 1,
            label: "0-1,000",
          },
          {
            id: 2,
            label: "1,001-5,000",
          },
          {
            id: 3,
            label: "5,001-10,000",
          },
          {
            id: 4,
            label: "10,001-20,000",
          },
          {
            id: 5,
            label: "เท่าไรก็ได้ ขอให้หาย",
          },
        ],
      },
      {
        id: 3,
        answer: [],
        controlType: "checkbox",
        title: "3. ปัญหาผิวอื่นๆ ที่ท่านมี \n (เลือกได้มากกว่า 1 ข้อ)",
        useoThorText: true,
        options: [
          {
            id: 1,
            label: "ปัญหา ฝ้า-กระ ",
          },
          {
            id: 2,
            label: "ปัญหาสิวและจุดด่างดำ",
          },
          {
            id: 3,
            label: "ปัญหาริ้วรอย เหี่ยวย่น",
          },
          {
            id: 4,
            label: "ปัญหาผิวคล้ำเสีย",
          },
          {
            id: 5,
            label: "ปัญหารูขุมขนกว้าง",
          },
          {
            id: 6,
            label: "อื่นๆ  ( โปรดระบุ )",
          },
        ],
      },
      {
        id: 4,
        answer: [],
        controlType: "checkbox",
        title: "4. สิ่งที่ท่านกังวลในการรักษาฝ้า \n (เลือกได้มากกว่า 1 ข้อ)",
        useoThorText: false,
        options: [
          {
            id: 1,
            label: "ราคา",
          },
          {
            id: 2,
            label: "ขั้นตอนการรักษา",
          },
          {
            id: 3,
            label: "ระยะเวลา",
          },
          {
            id: 4,
            label: "การลอก/ผลข้างเคียง",
          },
          {
            id: 5,
            label: "ฉันไม่มีความกังวล",
          },
        ],
      },
      {
        id: 5,
        answer: "",
        controlType: "radio",
        title:
          "5. เวลาที่สะดวกในการโทรติดต่อ \n เพื่อแจ้งการประเมินผลและแนะนำวิธีดูแล (ไม่บังคับ)",
        useoThorText: false,
        options: [
          {
            id: 1,
            label: "จ-ศ เวลา 9.30-12.00 น.",
          },
          {
            id: 2,
            label: "จ-ศ เวลา 13:00-17.00 น.",
          },
          {
            id: 3,
            label: "วันเสาร์-อาทิตย์ 13:00 - 17:00 น.",
          },
          {
            id: 4,
            label: "ไม่สะดวกติดต่อ",
          },
        ],
      },
    ],
    upload: {
      file: "",
      targetFile: null,
    },
    others: [],
  };

  const validateFormStep1 = (values) => {
    let errors = {};

    if (!values.name) {
      errors.name = "กรุณาระบุ ชื่อ-นามสกุล";
    }

    if (!values.email) {
      errors.email = "กรุณาระบุ E-mail";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "กรุณาระบุ E-mail ให้ถูกต้อง";
    }

    if (!values.tel) {
      errors.tel = "กรุณาระบุ เบอร์โทรศัพท์";
    } else if (values.tel.length < 10) {
      errors.tel = "กรุณาระบุ ให้ครบ 10 หลัก";
    }

    if (!values.policy) {
      errors.policy = "Required";
    }

    return errors;
  };

  const validateFormStep2 = (values) => {
    let errors = {};

    // const listEmpty = values.question.filter(
    //   (item) =>
    //     (item?.answer?.length === 0 || item?.answer === "") && item.id !== 5
    // );

    // if (listEmpty.length > 0) {
    //   let arr = [];
    //   listEmpty.forEach((item) => {
    //     arr.push({
    //       id: item.id,
    //       isError: true,
    //     });
    //   });
    //   errors.question = arr;
    // }

    // if (listEmpty.length > 0) {
    //   let arr = [];
    //   listEmpty.forEach((item, index) => {
    //     if (item.id !== 5) {
    //       const dataType = typeof item.answer;
    //       if (dataType === "string" && item.answer === "") {
    //         errors[`question[${index}].answer`] = "Required";
    //       }

    //       if (Array.isArray(item.answer) && item.answer.length === 0) {
    //         errors[`question[${index}].answer`] = "Required";
    //       }
    //     }
    //   });
    // }

    // const question = values.question;
    // question.forEach((item, index) => {
    //   if (item.id !== 5) {
    //     const dataType = typeof item.answer;
    //     if (dataType === "string" && item.answer === "") {
    //       errors[`question[${index}].answer`] = "Required";
    //     }

    //     if (Array.isArray(item.answer) && item.answer.length === 0) {
    //       errors[`question[${index}].answer`] = "Required";
    //     }
    //   }
    // });

    return errors;
  };

  const validateFormStep3 = (values) => {
    let errors = { upload: {} };

    if (!values.upload.file) {
      errors.upload["file"] = "Required";
    } else {
      errors = {};
    }

    return errors;
  };

  const validate = (values) => {
    if (activeTabKey === 1) {
      return {
        ...validateFormStep1(values),
      };
    }
    else if (activeTabKey === 3) {
      return {
        ...validateFormStep3(values),
      };
    }
  };

  const handleSubmit = (values) => {
    const url = "https://mesoestetic-dev.com/insert";

    const arrQuestion = values.question.map((item) =>
      Object.assign({
        id: item.id,
        answer: item.answer,
      })
    );

    const formData = new FormData();
    const uploadData = values.upload.targetFile;
    const uploadname = values.upload.file;
    formData.append("urls", window.location.origin);
    formData.append("userid", posts + 1);
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("tel", values.tel);
    formData.append("policy", values.policy);
    formData.append("question", JSON.stringify(arrQuestion));
    formData.append("upload", uploadData);
    formData.append("uploadname", uploadname);
    formData.append("others", JSON.stringify(values.others));
    formData.append("date", formatDate);

    // console.log("values.target.files[0]", uploadData);
    // console.log("  values.userid ", values.userid);
    // console.log("  values.userid ", values.userid);
    console.log("  values.others ", values.others[0]);

    // const params = {
    //   userid: values.userid,
    //   name: values.name,
    //   email: values.email,
    //   tel: values.tel,
    //   policy: values.policy,
    //   question: JSON.stringify(arrQuestion),
    //   upload: formData,
    // };
    // console.log("params", params);
    // console.log("  values.userid ", posts+1);

    Axios.post(url, formData)
      .then((res) => {
        console.log("res.data", res.data);
        window.location.href = "/meso-questions/successed";
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };

  return (
    <div>
      <div className="bg-question form">
        <div className="py-5">
          <img className="logo" src={logo} alt="" />
        </div>
        <div className="body">
          <div className="title text-center">
            <Nav
              fill
              variant="tabs"
              defaultActiveKey={1}
              activeKey={activeTabKey}
            >
              {tabs.map((item, index) => (
                <Nav.Item key={index}>
                  <Nav.Link eventKey={item.id}>{item.name}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>
          <div className="form-customer">
            <Formik
              initialValues={initialValues}
              validate={validate}
              onSubmit={handleSubmit}
              // validateOnChange={false}
            >
              {(formikProps) => {
                return (
                  <form onSubmit={formikProps.handleSubmit}>
                    <div>{displayContent(activeTabKey)}</div>
                    <div>
                      <Form.Group className="form-group text-center">
                        <Button
                          type="button"
                          className="btn-start"
                          onClick={() => handleClickBack()}
                          disabled={activeTabKey === 1}
                        >
                          ย้อนกลับ
                        </Button>
                        <Button
                          type="button"
                          className="btn-start"
                          onClick={() => handleClickNext(formikProps)}
                        >
                          {activeTabKey === 3 ? "บันทึกข้อมูล" : "หน้าต่อไป"}
                        </Button>
                      </Form.Group>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormRegister;
